{
  "name": "para-beta",
  "version": "1.0.0-beta.5",
  "private": true,
  "dependencies": {
    "@floating-ui/react-dom": "^2.0.0",
    "@fortawesome/fontawesome-svg-core": "^1.2.36",
    "@fortawesome/free-brands-svg-icons": "^5.15.4",
    "@fortawesome/free-regular-svg-icons": "^5.15.4",
    "@fortawesome/free-solid-svg-icons": "^5.15.4",
    "@fortawesome/react-fontawesome": "^0.2.0",
    "@react-pdf/renderer": "^3.4.2",
    "@tanstack/react-query": "^5.59.0",
    "@tanstack/react-table": "^8.19.2",
    "@testing-library/jest-dom": "^4.2.4",
    "@testing-library/react": "^9.5.0",
    "@testing-library/user-event": "^7.2.1",
    "axios": "^1.5.1",
    "canvas-txt": "^4.1.1",
    "classnames": "^2.2.6",
    "dexie": "^3.2.2",
    "echarts-for-react": "^3.0.2",
    "env-cmd": "^10.1.0",
    "es6-template-strings": "^2.0.1",
    "force-graph": "^1.43.5",
    "http-proxy-middleware": "^1.0.3",
    "lodash": "^4.17.21",
    "luxon": "^3.0.1",
    "mathjs": "^10.1.1",
    "n3": "^1.21.1",
    "numeral": "^2.0.6",
    "react": "^18.2.0",
    "react-beautiful-dnd": "^13.1.1",
    "react-calendar": "^4.4.0",
    "react-dom": "^18.2.0",
    "react-grid-layout": "^1.4.4",
    "react-range": "^1.8.14",
    "react-resizable-panels": "^2.1.3",
    "react-router-dom": "^6.14.1",
    "react-scripts": "^5.0.1",
    "react-select": "^5.8.0",
    "react-svg-curved-path": "^1.0.4",
    "react-tooltip": "^5.28.0",
    "recharts": "^2.1.15",
    "use-debounce": "^10.0.3",
    "uuid": "^7.0.3",
    "victor": "^1.1.0",
    "xlsx": "^0.18.5",
    "zustand": "^4.4.4"
  },
  "scripts": {
    "start": "npm run generate-build-version && react-scripts start",
    "build": "npm run generate-build-version && npm run set-favicon && react-scripts --max-old-space-size=4096 build",
    "test": "npm run generate-build-version && react-scripts test",
    "eject": "react-scripts eject",
    "lint": "eslint src/",
    "lint:fix": "eslint src/ --fix",
    "lint:styles": "stylelint \"./src/**/*.(scss)\" --fix",
    "pre-commit": "npx lint-staged",
    "storybook": "npm run generate-build-version && npm run storybook:prerun && storybook dev -p 6006",
    "build-storybook": "npm run generate-build-version && npm run storybook:prerun && storybook build",
    "storybook:prerun": "npm run generate-icons-index && npm run generate-colors-file",
    "generate-icons-index": "node scripts/generate-icons-index.js",
    "generate-colors-file": "node scripts/generate-colors-file.js",
    "set-favicon": "node -r dotenv/config scripts/set-favicon.ts",
    "generate-build-version": "node -r dotenv/config scripts/generate-build-version.ts"
  },
  "lint-staged": {
    "**/*.{js,jsx,ts,tsx}": [
      "eslint"
    ],
    "**/*.scss": [
      "stylelint --syntax scss"
    ]
  },
  "browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  },
  "devDependencies": {
    "@storybook/addon-a11y": "^7.2.2",
    "@storybook/addon-essentials": "^7.2.2",
    "@storybook/addon-interactions": "^7.2.2",
    "@storybook/addon-links": "^7.2.2",
    "@storybook/addon-onboarding": "^1.0.8",
    "@storybook/blocks": "^7.2.2",
    "@storybook/preset-create-react-app": "^7.2.2",
    "@storybook/react": "^7.2.2",
    "@storybook/react-webpack5": "^7.2.2",
    "@storybook/testing-library": "^0.2.0",
    "@tanstack/react-query-devtools": "^5.58.0",
    "@types/canvas-txt": "^3.0.4",
    "@types/lodash": "^4.14.194",
    "@types/luxon": "^3.3.0",
    "@types/n3": "^1.21.0",
    "@types/node": "^18.19.26",
    "@types/numeral": "^2.0.3",
    "@types/react-beautiful-dnd": "^13.1.4",
    "@types/react-grid-layout": "^1.3.5",
    "@types/three": "^0.155.1",
    "@types/uuid": "^9.0.2",
    "@typescript-eslint/parser": "^5.59.1",
    "babel-plugin-named-exports-order": "^0.0.2",
    "dotenv-cli": "^7.4.1",
    "eslint-config-prettier": "^8.8.0",
    "eslint-plugin-flowtype": "^4.7.0",
    "eslint-plugin-import": "^2.20.2",
    "eslint-plugin-prettier": "^4.2.1",
    "eslint-plugin-react": "^7.19.0",
    "prettier": "^2.0.5",
    "prop-types": "^15.8.1",
    "sass": "^1.63.6",
    "storybook": "^7.2.2",
    "storybook-addon-mock": "^4.2.1",
    "storybook-addon-react-router-v6": "^2.0.7",
    "stylelint": "^16.1.0",
    "stylelint-config-standard-scss": "^12.0.0",
    "three": "^0.140.2",
    "typescript": "^5.1.6",
    "webpack": "^5.88.2"
  },
  "overrides": {
    "react-scripts": {
      "typescript": "^5.1.6"
    }
  }
}
